<template>
  <div style="min-height: calc(100vh - 60px); margin: 10px 0">
    <el-row>
      <el-col :span="12" :offset="6">
    <div style="margin: 10px 0">
      <el-input size="mid" style="width: 400px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="title"></el-input>
      <!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
      <!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
      <el-button class="ml-5" type="success" @click="load" size="mid">搜索</el-button>
      <el-button type="primary" class="ml-5" size="mid" @click="handleAdd">发起讨论</el-button>
    </div>

    <div style="margin: 10px 0">

    </div>

    <div style="margin: 10px 0;">
      <div v-for="item in tableData" style="padding: 20px; border-radius: 10px; background-color: #fff; margin: 10px 0">
        <div style="font-size: 20px; font-weight: bold; color: #1E90FF; cursor: pointer" @click="$router.push('/front/discussDetail?id=' + item.id+'&type=3')">#{{ item.title }}</div>
        <div style="font-size: 20px; font-weight: bold;">{{item.content}}</div>
        <div style="margin-top: 15px; font-size: 14px">
          <span>
        <img :src="item.user?item.user.avatarUrl:null" alt=""
             style="width: 40px;  position: relative; top: 8px; right: 5px">{{item.user?item.user.nickname:'' }}</span>
          <span><i class="el-icon-time" style="margin-right: 5px; margin-left: 20px"></i>{{ item.createTime }}</span>
          <span style="margin-right: 5px; margin-left: 20px">{{ item.updateTime }}</span>
          <span><i class="el-icon-chat-dot-round" style="margin-right: 5px; margin-left: 20px"></i>{{ item.count }}</span>
          <span >
               <el-popconfirm
                   style="float: right; font-size: 16px;margin-left: 10px"
                   v-if="item.userId === user.id"
                   class="ml-5"
                   confirm-button-text='确定'
                   cancel-button-text='我再想想'
                   icon="el-icon-info"
                   icon-color="red"
                   title="您确定删除吗？"
                   @confirm="del(item.id)"
               > <el-button   v-if="item.userId === user.id" type="text" style="float: right; font-size: 16px;color: red"  slot="reference">删除</el-button>
          </el-popconfirm>
          <el-button v-if="item.userId === user.id" type="text" style="float: right; font-size: 16px;margin-left: 10px" @click="handleEdit(item)">编辑</el-button>
          </span>
        </div>
      </div>
    </div>
    <div  style="padding: 10px; margin: 10px 0; background-color: #fff; border-radius: 10px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
      </el-col>
    </el-row>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="40%" :close-on-click-modal="false">
      <el-form label-width="60px" size="small" style="width: 100%">
        <el-form-item label="标题">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8}"
              placeholder="请输入内容"
              v-model="form.content">
          </el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Discuss",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      title: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      vis: false,
      content: '',
      comment: {},
      commentVis: false,
      praise: false,

      comments: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/discuss/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          title: this.title,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      if (!this.user.id) {
        this.$message.warning("请登录后操作")
        return
      }
      if (!this.form.title) {
        this.$message.error("标题不能为空！")
        return
      }
      if (!this.form.content) {
        this.$message.error("内容不能为空！")
        return
      }
      this.request.post("/discuss", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      if (!this.user.id) {
        this.$message.warning("请登录后操作")
        return
      }
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if(this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleEdit(row) {
      if (!this.user.id) {
        this.$message.warning("请登录后操作")
        return
      }
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
      this.$nextTick(() => {
        if(this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if(this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    del(id) {
      if (!this.user.id) {
        this.$message.warning("请登录后操作")
        return
      }
      this.request.delete("/discuss/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/discuss/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.title = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open(process.env.VUE_APP_BASE_URL+"article/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
